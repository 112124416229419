import React from "react";
import PropTypes from "prop-types";
import { Box, Center, Container, Group, Image, Text, Title } from "@mantine/core";
import { graphql } from 'gatsby';
import { Helmet } from "react-helmet";
import Layout from "../components/Layout";

const ReferencePageTemplate = ({ data }) => {
    const { markdownRemark: { frontmatter: reference, html, id } } = data;
    
    return (
        <Layout>
        <Helmet titleTemplate="%s | Referenzen">
            <Title>{`${reference.title}`}</Title>
            <Group position="apart">
                <Text fw="bold">Start Date</Text>
                <Text>{reference.date}</Text>
            </Group>
            <Group position="apart">
                <Text fw="bold">End Date</Text>
                <Text>{reference.endDate}</Text>
            </Group>
            <Group position="apart">
                <Text fw="bold">Partner</Text>
                <Text>{reference.partner}</Text>
            </Group>
            {/* <Group position="apart">
                <Text fw="bold">Partner Logo</Text>
                <Image src={reference.partner?.logoUrl} alt={reference.partner?.title} w="3rem" height="auto"/>
            </Group> */}
        </Helmet>
        </Layout>
    );
}

export default ReferencePageTemplate;

export const pageQuery = graphql`
    query ReferencePage($id: String!) {
        markdownRemark(id: { eq: $id }) {
            html
            id
            frontmatter {
                title
                date
                endDate
                partner
            }
        }
    }
`;
